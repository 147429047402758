import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Navigate,
} from "react-router-dom";
import Sidebar from "./Components/Sidebar/Sidebar";
import Header from "./Components/Header/Header";
import AddAdmin from "./Pages/Admin/Create";
import Manage from "./Pages/Admin/Manage";
import ViewAdmin from "./Pages/Admin/Viewadmin";
import ViewUsers from "./Pages/Users/ViewUsers";
import Dashboard from "./Pages/Dasboard/Dashboard";
import Login from "./Pages/Login/Login";
import DepositForm from "./Pages/Payment/deposte";
import ConfirmPayment from "./Pages/Payment/confirm";
import KYCVerification from "./Pages/Payment/verification";
import DetailView from "./Pages/Users/DetailView";
import PaymentForm from "./Pages/Payment/Mir";
import PaymentConfirm from "./Pages/PaymentConfirm/PaymentConfirm";

function Layout() {
  const location = useLocation();

  // Determine if the current route is the login page
  const isLoginPage = location.pathname === "/login";
  const isFindPage = location.pathname === "/payment-status";

  return (
    <div className="flex">
      {/* Conditionally render the sidebar */}
      {!isLoginPage && !isFindPage && <Sidebar />}

      <div className="flex-grow bg-slate-100">
        {/* Conditionally render the header */}
        {!isLoginPage && !isFindPage && <Header />}
        <Routes>
          <Route path="/" element={<Navigate to="/dashboard" />} />
          <Route path="/login" element={<Login />} />
          <Route path="/admin/add" element={<AddAdmin />} />
          <Route path="/admin/view" element={<ViewAdmin />} />
          <Route path="/admin/Manage/:id" element={<Manage />} />
          <Route path="/users/View" element={<ViewUsers />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/user/view/:id" element={<DetailView />} />
          <Route path="/kyc" element={<KYCVerification />} />
          <Route path="/payment" element={<DepositForm />} />
          <Route path="/form" element={<PaymentForm />} />
          <Route path="/payment-status" element={<PaymentConfirm />} />
        </Routes>
      </div>
    </div>
  );
}

function App() {
  return (
    <Router>
      <Layout />
    </Router>
  );
}

export default App;
