import React from "react";

function AdminModals({ isOpen, onClose, onConfirm ,title,message}) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-1/3">
        <h3 className="text-lg font-medium text-gray-900 mb-4">
          Confirm Action
        </h3>
        <p className="text-gray-700 mb-6">
          {message}
          {/* Are you sure you want to create this admin? */}
        </p>
        <div className="flex justify-end gap-4">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-400 text-white rounded-lg hover:bg-gray-600 transition-colors duration-200"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 bg-gray-800 text-white rounded-lg hover:bg-gray-600 transition-colors duration-200"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
}

export default AdminModals;
