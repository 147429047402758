import { useState } from "react";
import { FaUserCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import { doAdminLogin, doAdminLogout } from "../../apis/adminAxios";

const Header = () => {
  const navigate = useNavigate();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // To retrieve
  const admin = localStorage.getItem("admin");
  // Parse it back into an object
  const adminDetails = JSON.parse(admin);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLogout = async (e) => {
    e.preventDefault();

    const payload = {
      strLoginUserId: adminDetails.fkAdminId,
      strtoken: adminDetails.token,
    };

    doAdminLogout(payload)
      .then((res) => {
        if (res.success == true) {
          localStorage.removeItem("admin");

          navigate("/login");
        } else {
          // localStorage.setItem("admin", JSON.stringify(res.adminDetails));
          // setTimeout(() => {
          //   setIsLoading(false);
          //   navigate("/dashboard");
          // }, 2000);
        }
      })
      .catch((error) => {});
  };

  return (
    <div className="bg-white shadow-sm p-4 flex justify-end items-center w-full h-20">
      {/* Company Logo */}
      {/* <div className="text-md font-medium text-gray-800">
    ISAP WALLET
      </div> */}

      {/* Profile Icon with Dropdown */}
      <div className="relative">
        <button
          onClick={toggleDropdown}
          className="flex items-center focus:outline-none"
        >
          <FaUserCircle className="text-3xl text-gray-600 hover:text-gray-800" />
        </button>

        {isDropdownOpen && (
          <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-lg shadow-lg">
            <a
              href="/profile"
              className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
            >
              Profile
            </a>
            <a
              href="/settings"
              className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
            >
              Settings
            </a>
            <a
              href="/login"
              className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
              onClick={handleLogout}
            >
              Logout
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
