import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { doGetAllAdmins, doUpdateAdmin } from "../../apis/adminAxios"; // Import your Axios instance
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import the CSS file
import AdminModals from "../../Components/Modals/AdminModals";

function Manage() {
  const { id } = useParams(); // Get the admin ID from the URL
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [active, setActive] = useState("");
  const [adminType, setAdminType] = useState("ADMIN"); // Default to ADMIN
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const admin = localStorage.getItem("admin");
  const adminDetails = JSON.parse(admin);
  useEffect(() => {
    if (!adminDetails) {
      navigate("/login");
    }
    const payload = {
      strLoginUserId: adminDetails.fkAdminId,
      fkAdminId: id,
      intSkipCount: 0, // Calculate skip based on page number
      intPageLimit: 1,
    };
    doGetAllAdmins(payload, adminDetails.token)
      .then((res) => {
        if (res.success === false) {
          toast.error(res.message);
        } else {
          setUsername(res.data[0].strName);
          setEmail(res.data[0].strEmail);
          // setPassword(res.data[0].strPassword); // In real scenarios, password wouldn't be stored like this
          if (res.data[0].strUserType == 1) {
            setAdminType("ADMIN");
          } else {
            setAdminType("SUB-ADMIN");
          }
        }
      })
      .catch((error) => {
        if (error.status == 401) {
          localStorage.removeItem("admin");
          navigate("/login");
        }
      });
  }, [id]);

  const handleModalConfirm = (e) => {
    e.preventDefault();
    let type = adminType == "ADMIN" ? 1 : 2;
    const payload = {
      strLoginUserId: adminDetails.fkAdminId,
      strStatus: active,
      strEmail: email,
      arryModuleDetails: [],
      fkAdminId: id,
      strName: username,
      strUserType: type,
    };
    doUpdateAdmin(payload, adminDetails.token)
      .then((res) => {
        if (res.success === false) {
          toast.error(res.message);
        } else {
          navigate("/admin/view");
        }
      })
      .catch((error) => {
        if (error.status == 401) {
          localStorage.removeItem("admin");
          navigate("/login");
        }
      });
  };
  const handleUpdate = (e) => {
    e.preventDefault();

    setModalMessage("Are you sure you want to update this admin?");
    setIsModalOpen(true);
  };

  const handleClear = () => {
    setUsername("");
    setEmail("");
    setActive("");
    setAdminType("ADMIN");
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="flex h-auto bg-gray-100 mt-20  ml-[260px]">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full mx-6">
        <h2 className="text-xl font-medium mb-6 text-gray-800">Manage Admin</h2>
        <form onSubmit={handleUpdate} className="space-y-4">
          <div className="flex gap-3">
            <div className="flex flex-col w-2/6">
              <label className="mb-1 text-gray-700" htmlFor="username">
                Username <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="px-4 py-2 bg-slate-100 text-gray-500 border border-gray-200 rounded-lg focus:outline-none focus:ring-1 focus:ring-gray-200"
                placeholder="Username"
                required
              />
            </div>

            <div className="flex flex-col w-2/6">
              <label className="mb-1 text-gray-700" htmlFor="email">
                Email <span className="text-red-500">*</span>
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="px-4 py-2 bg-slate-100 border border-gray-200 rounded-lg focus:outline-none focus:ring-1 focus:ring-gray-200"
                placeholder="Email"
                required
              />
            </div>
            {/* <div className="flex flex-col w-2/6">
              <label className="mb-1 text-gray-700" htmlFor="password">
                Password <span className="text-red-500">*</span>
              </label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="px-4 py-2 bg-slate-100 border border-gray-200 rounded-lg focus:outline-none focus:ring-1 focus:ring-gray-200"
                placeholder="Password"
                required
              />
            </div> */}
            <div className="flex flex-col w-2/6">
              <label className="mb-1 text-gray-700" htmlFor="adminType">
                Admin Type <span className="text-red-500">*</span>
              </label>
              <select
                id="adminType"
                value={adminType}
                onChange={(e) => setAdminType(e.target.value)}
                className="px-4 py-2 bg-slate-100 border border-gray-200 rounded-lg"
                required
              >
                <option value="ADMIN">ADMIN</option>
                <option value="SUB_ADMIN">SUB ADMIN</option>
              </select>
            </div>
          </div>
          <div className="flex flex-col w-2/6">
            <label className="mb-1 text-gray-700" htmlFor="adminStatus">
              Admin Status <span className="text-red-500">*</span>
            </label>
            <select
              id="adminStatus"
              value={active}
              onChange={(e) => setActive(e.target.value)}
              className="px-4 py-2 bg-slate-100 border border-gray-200 rounded-lg"
              required
            >
              <option value="N">active</option>
              <option value="M">inActive</option>
            </select>
          </div>

          <div className="flex justify-end gap-5 mt-6">
            <button
              type="button"
              onClick={handleClear}
              className="px-4 w-1/6 py-2 bg-gray-400 text-white font-semibold rounded-lg hover:bg-gray-800 transition-colors duration-200"
            >
              Clear
            </button>
            <button
              type="submit"
              className="px-4 py-2 w-1/6 bg-gray-800 text-white font-semibold rounded-lg hover:bg-gray-600 transition-colors duration-200"
            >
              Update
            </button>
          </div>
        </form>
      </div>
      <AdminModals
        isOpen={isModalOpen}
        onClose={handleModalClose}
        title="Confirm Action"
        message={modalMessage}
        onConfirm={handleModalConfirm}
      />
      <ToastContainer />
    </div>
  );
}

export default Manage;
