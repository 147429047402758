import React, { useState } from 'react';
import axios from 'axios';
import CryptoJS from 'crypto-js';

const PaymentForm = () => {
  const [amount, setAmount] = useState('10.23');
  const [amountcurr, setAmountcurr] = useState('USD');
  const [currency, setCurrency] = useState("MBC");
  const [number, setNumber] = useState('5412');
  const [description, setDescription] = useState(`Test payment of ${amount} ${amountcurr}`);
  const [trtype, setTrtype] = useState('1');
  const [account, setAccount] = useState('ACC016784');
  const [paytoken, setPaytoken] = useState('');
  const [backURL, setBackURL] = useState('');
  const [signature, setSignature] = useState('');

  const generateSignature = () => {
    let signatureString = `${amount}:${amountcurr}:${currency}:${number}:${encodeURIComponent(description)}:`;
    signatureString += `${trtype}:${account}:`;
    if (paytoken !== '') signatureString += `${paytoken}:`;
    if (backURL !== '') signatureString += `${backURL}:`;
    signatureString += '2ffdcf18-3ed7-69e0-d47f-8dad1df96115:lIlCSlUFDzG3iqTR2AP9';

    // Generate MD5 hash using crypto-js
    return CryptoJS.MD5(signatureString).toString().toUpperCase();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const signature = generateSignature();
    setSignature(signature);

    try {
      const response = await axios.post('https://fin.test.4g12hs.com/api/payment/start', {
        amount,
        amountcurr,
        currency,
        number,
        description,
        trtype,
        account,
        signature
      });

      console.log('Payment Response:', response.data);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit} className='ml-[260px]'>
      <input type="hidden" name="amount" value={amount} />
      <input type="hidden" name="amountcurr" value={amountcurr} />
      <input type="hidden" name="currency" value={currency} />
      <input type="hidden" name="number" value={number} />
      <input type="hidden" name="description" value={description} />
      <input type="hidden" name="trtype" value={trtype} />
      <input type="hidden" name="account" value={account} />
      <input type="hidden" name="signature" value={signature} />
      <button type="submit">Pay</button>
    </form>
  );
};

export default PaymentForm;
