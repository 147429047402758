// // export default ViewAdmin;
// import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import { FiEdit, FiTrash2, FiEye } from "react-icons/fi";
// import { doGetAllAdmins, doUpdateAdmin } from "../../apis/adminAxios"; // Import your Axios instance
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css"; // Import the CSS file
// import AdminModals from "../../Components/Modals/AdminModals";

// function ViewAdmin() {
//   const navigate = useNavigate();
//   const [admins, setAdmins] = useState([]); // State for admin data

//   const [filterType, setFilterType] = useState("");
//   const [filterStatus, setFilterStatus] = useState("");
//   const [selectedAdminId, setSelectedAdminId] = useState(null);
//   const [modalMessage, setModalMessage] = useState("");
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [currentPage, setCurrentPage] = useState(1); // Start from page 1
//   const [count, setCount] = useState(0);

//   const itemsPerPage = 20;

//   const admin = localStorage.getItem("admin");
//   const adminDetails = JSON.parse(admin);

//   // Fetch admins from API
//   useEffect(() => {
//     const fetchAdmins = async () => {
//       try {
//         let type;
//         if (filterType) {
//           type = filterType == "ADMIN" ? 1 : 2;
//         }

//         const payload = {
//           strLoginUserId: adminDetails.fkAdminId,
//           strStatus: filterStatus || "",
//           strUserType: type || "",
//           intSkipCount: (currentPage - 1) * itemsPerPage, // Calculate skip based on page number
//           intPageLimit: itemsPerPage,
//         };
//         doGetAllAdmins(payload, adminDetails.token)
//           .then((res) => {
//             if (res.success === false) {
//               setAdmins([]);
//             } else {
//               setAdmins(res.data);
//               setCount(res.count);

//             }
//           })
//           .catch((error) => {
//             if (error.status == 401) {
//               localStorage.removeItem("admin");

//               navigate("/login");
//             }
//           });
//       } catch (error) {
//         if (error.status == 401) {
//           localStorage.removeItem("admin");

//           navigate("/login");
//         }
//       }
//     };

//     fetchAdmins();
//   }, [currentPage, filterStatus, filterType]);

//   const handlePageChange = (page) => {
//     setCurrentPage(page);
//   };
//   const handleIconClick = (adminId, actionType) => {
//     setSelectedAdminId(adminId);
//     if (actionType === "delete") {
//       setModalMessage("Are you sure you want to delete this admin?");
//     } else if (actionType === "view") {
//       setModalMessage("Viewing admin details.");
//     }
//     setIsModalOpen(true);
//   };

//   const handleModalConfirm = () => {
//     setIsModalOpen(false);
//     let payload = {
//       strLoginUserId: adminDetails.fkAdminId,
//       fkAdminId: selectedAdminId,
//       strStatus: "D",
//       arryModuleDetails: [],
//       strEmail: adminDetails.strEmail,
//       strName: adminDetails.strName,
//       strUserType: adminDetails.strUserType,
//     };
//     doUpdateAdmin(payload, adminDetails.token)
//       .then((res) => {
//         if (res.success === false) {
//           toast.error(res.message || "Failed to create admin."); // Error toast
//         } else {
//           toast.success("Admin deleted successfully!"); // Success toast
//           setAdmins((prevAdmins) =>
//             prevAdmins.filter((admin) => admin.fkAdminId !== selectedAdminId)
//           );
//           setSelectedAdminId(null);
//         }
//       })
//       .catch((error) => {
//         if (error.status == 401) {
//           localStorage.removeItem("admin");
//           navigate("/login");
//         } else {
//           toast.error("An error occurred. Please try again."); // Error toast
//         }
//       });
//   };

//   const handleModalClose = () => {
//     setIsModalOpen(false);
//   };

//   const handleNavigate = (id) => {
//     navigate(`/admin/manage/${id}`);
//   };

//   return (
//     <div className="ml-[260px]">
//       <div className="bg-white p-6 mt-5 mx-6 border mb-6 rounded-lg">
//         <h3 className="text-lg font-medium mb-4">Filter Admins</h3>
//         <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-5 ">
//           {/* Name Search */}
//           {/* <div className="">
//             <label
//               htmlFor="name"
//               className="block text-sm font-medium text-gray-700 "
//             >
//               Name
//             </label>
//             <input
//               type="text"
//               id="name"
//               placeholder="Enter Admin Name"
//               value={filterName}
//               onChange={(e) => setFilterName(e.target.value)}
//               className="mt-1  px-3 h-10 block w-full bg-slate-100 border-gray-300 rounded-md shadow-sm  sm:text-sm"
//             />
//           </div> */}

//           {/* Date Search */}
//           {/* <div>
//             <label
//               htmlFor="date"
//               className="block text-sm font-medium text-gray-700"
//             >
//               Date
//             </label>
//             <input
//               type="date"
//               id="date"
//               value={filterDate}
//               onChange={(e) => setFilterDate(e.target.value)}
//               className="mt-1 h-10 px-3 block w-full bg-slate-100 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
//             />
//           </div> */}

//           {/* Admin Type Dropdown */}
//           <div>
//             <label
//               htmlFor="type"
//               className="block text-sm font-medium text-gray-700"
//             >
//               Admin Type
//             </label>
//             <select
//               id="type"
//               value={filterType}
//               onChange={(e) => setFilterType(e.target.value)}
//               className="mt-1 h-10 block w-full bg-slate-100 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
//             >
//               <option value="">Select Type</option>
//               <option value="ADMIN" className="px-3">
//                 ADMIN
//               </option>
//               <option value="SUB_ADMIN" className="px-3">
//                 SUB-ADMIN
//               </option>
//             </select>
//           </div>
//           {/* Admin status Dropdown */}
//           <div>
//             <label
//               htmlFor="type"
//               className="block text-sm font-medium text-gray-700"
//             >
//               Admin Status
//             </label>
//             <select
//               id="type"
//               value={filterStatus}
//               onChange={(e) => setFilterStatus(e.target.value)}
//               className="mt-1 h-10 block w-full bg-slate-100 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
//             >
//               <option value="">Select Status</option>
//               <option value="N" className="px-3">
//                 active
//               </option>
//               <option value="M" className="px-3">
//                 inActive
//               </option>
//             </select>
//           </div>
//         </div>
//         <div className="flex justify-end gap-3">
//           <button
//             type="button"
//             onClick={() => {
//               setFilterType("");
//               setFilterStatus("");
//             }}
//             className="px-4 w-1/6 py-2 bg-gray-400 text-white  rounded-lg hover:bg-gray-800 transition-colors duration-200"
//           >
//             Clear
//           </button>
//           <button
//             type="submit"
//             className="px-4 py-2 w-1/6 bg-gray-800 text-white  rounded-lg hover:bg-gray-600 transition-colors duration-200"
//           >
//             Update
//           </button>
//         </div>
//       </div>
//       <div className="p-6 bg-white rounded-lg shadow-md mt-6  mx-6  mb-10 h-auto">
//         <h2 className="text-lg font-medium mb-6">View Admins</h2>
//         <table className="w-full table-auto ">
//           <thead>
//             <tr className="bg-gray-800 text-white text-sm leading-normal">
//               <th className="py-3 px-6 text-left">Name</th>
//               <th className="py-3 px-6 text-left">Email</th>
//               <th className="py-3 px-6 text-left">Admin Type</th>
//               <th className="py-3 px-6 text-left">Admin Status</th>
//               <th className="py-3 px-6 text-center">Actions</th>
//             </tr>
//           </thead>
//           <tbody className="text-gray-600 text-sm font-light">
//             {admins.map((admin) => (
//               <tr
//                 key={admin.fkAdminId}
//                 className="border-b border-gray-200 hover:bg-gray-100 font-normal"
//               >
//                 <td className="py-3 px-6  text-left  font-normal ">
//                   {admin.strName}
//                 </td>
//                 <td className="py-3 px-6 text-left  font-normal ">
//                   {admin.strEmail}
//                 </td>
//                 <td className="py-3 px-6 text-left ">
//                   {/* <span className="bg-green-200  font-medium  py-1 px-1 rounded-md"> */}
//                     {admin.strUserType == 1 ? "ADMIN" : "SUB-ADMIN"}
//                   {/* </span> */}
//                 </td>
//                 <td className="py-3 px-6 text-left">
//                   <span
//                     // className={`${admin.strStatus == "N"}? :bg-red-300 text-white font-medium py-1 px-1 rounded-md`}
//                     className={`${
//                       admin.strStatus === "N" ? "bg-green-500 text-white font-medium py-1 px-1 rounded-md" : "bg-red-500 text-white font-medium py-1 px-1 rounded-md"
//                     }`}
//                   >
//                     {admin.strStatus == "N" ? "active" : "inActive"}
//                   </span>
//                 </td>

//                 <td className="py-3 px-6 text-center">
//                   <div className="flex justify-center gap-2">
//                     {/* <div className="bg-red-100 text-red-400 flex justify-center items-center rounded-md w-8 h-8 hover:scale-105 cursor-pointer">
//                       <FiEye className="text-md" />
//                     </div> */}
//                     <div
//                       className="bg-green-100 text-green-400 flex justify-center items-center rounded-md w-8 h-8 hover:scale-110 cursor-pointer"
//                       onClick={() => handleNavigate(admin.fkAdminId)}
//                     >
//                       <FiEdit className="text-lg" />
//                     </div>
//                     <div className="bg-orange-200 text-orange-600 flex justify-center items-center rounded-md w-8 h-8 hover:scale-110 cursor-pointer">
//                       <FiTrash2
//                         className="text-lg"
//                         onClick={() =>
//                           handleIconClick(admin.fkAdminId, "delete")
//                         }
//                       />
//                     </div>
//                   </div>
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//         {/* Pagination Controls */}
//         <div className="mt-4 flex justify-center space-x-2">
//           {/* First Page Button */}
//           {currentPage > 1 && (
//             <button
//               onClick={() => handlePageChange(1)}
//               className="px-3 py-1 rounded-lg bg-gray-200"
//             >
//               First
//             </button>
//           )}

//           {/* Page Number Buttons */}
//           {[...Array(Math.ceil(count / itemsPerPage))].map((_, index) => (
//             <button
//               key={index}
//               onClick={() => handlePageChange(index + 1)}
//               className={`px-3 py-1 rounded-lg ${
//                 currentPage === index + 1
//                   ? "bg-gray-800 text-white"
//                   : "bg-gray-200"
//               }`}
//             >
//               {index + 1}
//             </button>
//           ))}

//           {/* Last Page Button */}
//           {currentPage < Math.ceil(count / itemsPerPage) && (
//             <button
//               onClick={() => handlePageChange(Math.ceil(100 / itemsPerPage))}
//               className="px-3 py-1 rounded-lg bg-gray-200"
//             >
//               Last
//             </button>
//           )}
//         </div>
//       </div>{" "}
//       {/* Adjusted margins */}
//       {/* Modal for Confirming Actions */}
//       <AdminModals
//         isOpen={isModalOpen}
//         onClose={handleModalClose}
//         title="Confirm Action"
//         message={modalMessage}
//         onConfirm={handleModalConfirm}
//       />
//       <ToastContainer />
//     </div>
//   );
// }

// export default ViewAdmin;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import { doGetAllAdmins, doUpdateAdmin } from "../../apis/adminAxios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminModals from "../../Components/Modals/AdminModals";

function ViewAdmin() {
  const navigate = useNavigate();
  const [admins, setAdmins] = useState([]);
  const [filterType, setFilterType] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [selectedAdminId, setSelectedAdminId] = useState(null);
  const [modalMessage, setModalMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);

  const itemsPerPage = 20;
  const admin = JSON.parse(localStorage.getItem("admin"));

  useEffect(() => {
    if (!admin) {
      navigate("/login");
    }

    const fetchAdmins = async () => {
      try {
        const type =
          filterType === "ADMIN" ? 1 : filterType === "SUB_ADMIN" ? 2 : "";
        const payload = {
          strLoginUserId: admin.fkAdminId,
          strStatus: filterStatus || "",
          strUserType: type || "",
          intSkipCount: (currentPage - 1) * itemsPerPage,
          intPageLimit: itemsPerPage,
        };

        const res = await doGetAllAdmins(payload, admin.token);
        if (res.success === false) {
          setAdmins([]);
        } else {
          setAdmins(res.data);
          setCount(res.count);
        }
      } catch (error) {
        if (error.response?.status === 401) {
          localStorage.removeItem("admin");
          navigate("/login");
        }
      }
    };

    fetchAdmins();
  }, [currentPage, filterStatus, filterType, admin, admin, navigate]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleIconClick = (adminId, actionType) => {
    setSelectedAdminId(adminId);
    if (actionType === "delete") {
      setModalMessage("Are you sure you want to delete this admin?");
      setIsModalOpen(true);
    }
  };

  const handleModalConfirm = async () => {
    setIsModalOpen(false);
    try {
      const payload = {
        strLoginUserId: admin.fkAdminId,
        fkAdminId: selectedAdminId,
        strStatus: "D",
      };
      const res = await doUpdateAdmin(payload, admin.token);
      if (res.success === false) {
        toast.error(res.message || "Failed to delete admin.");
      } else {
        toast.success("Admin deleted successfully!");
        setAdmins((prevAdmins) =>
          prevAdmins.filter((admin) => admin.fkAdminId !== selectedAdminId)
        );
      }
    } catch (error) {
      if (error.response?.status === 401) {
        localStorage.removeItem("admin");
        navigate("/login");
      } else {
        toast.error("An error occurred. Please try again.");
      }
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleNavigate = (id) => {
    navigate(`/admin/manage/${id}`);
  };

  return (
    <div className="ml-[260px]">
      <div className="bg-white p-6 mt-5 mx-6 border mb-6 rounded-lg">
        <h3 className="text-lg font-medium mb-4">Filter Admins</h3>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-5">
          <div>
            <label
              htmlFor="type"
              className="block text-sm font-medium text-gray-700"
            >
              Admin Type
            </label>
            <select
              id="type"
              value={filterType}
              onChange={(e) => setFilterType(e.target.value)}
              className="mt-1 h-10 block w-full bg-slate-100 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            >
              <option value="">Select Type</option>
              <option value="ADMIN">ADMIN</option>
              <option value="SUB_ADMIN">SUB-ADMIN</option>
            </select>
          </div>
          <div>
            <label
              htmlFor="status"
              className="block text-sm font-medium text-gray-700"
            >
              Admin Status
            </label>
            <select
              id="status"
              value={filterStatus}
              onChange={(e) => setFilterStatus(e.target.value)}
              className="mt-1 h-10 block w-full bg-slate-100 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            >
              <option value="">Select Status</option>
              <option value="N">active</option>
              <option value="M">inActive</option>
            </select>
          </div>
        </div>
        <div className="flex justify-end gap-3">
          <button
            type="button"
            onClick={() => {
              setFilterType("");
              setFilterStatus("");
            }}
            className="px-4 w-1/6 py-2 bg-gray-400 text-white rounded-lg hover:bg-gray-800 transition-colors duration-200"
          >
            Clear
          </button>
          <button
            type="submit"
            className="px-4 py-2 w-1/6 bg-gray-800 text-white rounded-lg hover:bg-gray-600 transition-colors duration-200"
          >
            Update
          </button>
        </div>
      </div>
      <div className="p-6 bg-white rounded-lg shadow-md mt-6 mx-6 mb-10 h-auto">
        <h2 className="text-lg font-medium mb-6">View Admins</h2>
        <table className="w-full table-auto">
          <thead>
            <tr className="bg-gray-800 text-white text-sm leading-normal">
              <th className="py-3 px-6 text-left">Name</th>
              <th className="py-3 px-6 text-left">Email</th>
              <th className="py-3 px-6 text-left">Admin Type</th>
              <th className="py-3 px-6 text-left">Admin Status</th>
              <th className="py-3 px-6 text-center">Actions</th>
            </tr>
          </thead>
          <tbody className="text-gray-600 text-sm font-light">
            {admins.map((admin) => (
              <tr
                key={admin.fkAdminId}
                className="border-b border-gray-200 hover:bg-gray-100 font-normal"
              >
                <td className="py-3 px-6 text-left">{admin.strName}</td>
                <td className="py-3 px-6 text-left">{admin.strEmail}</td>
                <td className="py-3 px-6 text-left">
                  {admin.strUserType === 1 ? "ADMIN" : "SUB-ADMIN"}
                </td>
                <td className="py-3 px-6 text-left">
                  <span
                    className={`${
                      admin.strStatus === "N"
                        ? "bg-green-500 text-white font-medium py-1 px-1 rounded-md"
                        : "bg-red-500 text-white font-medium py-1 px-1 rounded-md"
                    }`}
                  >
                    {admin.strStatus === "N" ? "active" : "inActive"}
                  </span>
                </td>
                <td className="py-3 px-6 text-center">
                  <div className="flex justify-center gap-2">
                    <div
                      className="bg-green-100 text-green-400 flex justify-center items-center rounded-md w-8 h-8 hover:scale-110 cursor-pointer"
                      onClick={() => handleNavigate(admin.fkAdminId)}
                    >
                      <FiEdit className="text-lg" />
                    </div>
                    <div
                      className="bg-orange-200 text-orange-600 flex justify-center items-center rounded-md w-8 h-8 hover:scale-110 cursor-pointer"
                      onClick={() => handleIconClick(admin.fkAdminId, "delete")}
                    >
                      <FiTrash2 className="text-lg" />
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="mt-4 flex justify-center space-x-2">
          {currentPage > 1 && (
            <button
              onClick={() => handlePageChange(1)}
              className="px-3 py-1 rounded-lg bg-gray-200"
            >
              First
            </button>
          )}
          {[...Array(Math.ceil(count / itemsPerPage))].map((_, index) => (
            <button
              key={index}
              onClick={() => handlePageChange(index + 1)}
              className={`px-3 py-1 rounded-lg ${
                currentPage === index + 1
                  ? "bg-gray-800 text-white"
                  : "bg-gray-200"
              }`}
            >
              {index + 1}
            </button>
          ))}
          {currentPage < Math.ceil(count / itemsPerPage) && (
            <button
              onClick={() => handlePageChange(Math.ceil(count / itemsPerPage))}
              className="px-3 py-1 rounded-lg bg-gray-200"
            >
              Last
            </button>
          )}
        </div>
      </div>
      <AdminModals
        message={modalMessage}
        isOpen={isModalOpen}
        onConfirm={handleModalConfirm}
        onClose={handleModalClose}
      />
      <ToastContainer />
    </div>
  );
}

export default ViewAdmin;
