import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const DepositForm = () => {
  const [currency, setCurrency] = useState("USD");
  const [amount, setAmount] = useState("");
  const [paymentId, setPaymentId] = useState(null);
  const [payerId, setPayerId] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const confirmPayment = async () => {
      const params = new URLSearchParams(location.search);
      const paymentId = params.get("paymentId");
      const payerId = params.get("PayerID");
      if (paymentId && payerId) {
        setPaymentId(paymentId);
        setPayerId(payerId);
      } else {
        alert("payer id does not fetch");
      }
    };

    confirmPayment();
  }, [location.search, navigate]);

  const handlePaymentCreate = async () => {
    try {
      const response = await fetch(
        "http://localhost:3000/v2/api/payment/payment_create",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            fkUserId: "654e738bbd90a661c3fc497a",
            amount,
            currency,
          }),
        }
      );
      const result = await response.json();
      console.log(result, "000");

      //   const data = await response.json();
      if (result.success) {
        // Redirect to PayPal approval URL
        const approvalUrl = result.data.links.find(
          (link) => link.rel === "approval_url"
        ).href;
        window.location.href = approvalUrl;
      } else {
        console.error("Payment creation failed:", result.message);
      }
    } catch (error) {
      console.error("Error creating payment:", error);
    }
  };
  const handlePaymentExecute = async () => {
    try {
      const response = await fetch(
        "http://localhost:3000/v2/api/payment/payment_execute",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ paymentId, payerId }),
        }
      );
      const result = await response.json();

      if (result.success) {
        setPaymentStatus(result.data.state);
      } else {
        console.error("Payment execution failed:", result.message);
      }
    } catch (error) {
      console.error("Error executing payment:", error);
    }
  };
  const handlePaymentStatus = async () => {
    try {
      const response = await fetch(
        "http://localhost:3000/v2/api/payment/payment_status",

        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ paymentId, payerId }),
        }
      );
      const result = await response.json();

      if (result.success) {
        setPaymentStatus(result.data.state);
      } else {
        console.error("Failed to retrieve payment status:", result.message);
      }
    } catch (error) {
      console.error("Error fetching payment status:", error);
    }
  };

  return (
    <div className="ml-[260px] flex flex-col bg-slate-100">
      <h2>Create a Payment</h2>
      <input
        type="number"
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
        placeholder="Enter amount"
      />
      <button onClick={handlePaymentCreate}>Create Payment</button>

      {paymentId && (
        <>
          <h2>Execute Payment</h2>
          <input
            type="text"
       
            placeholder="Enter Payer ID"
          />
          <button onClick={handlePaymentExecute}>Execute Payment</button>
        </>
      )}

      {paymentStatus && <p>Payment Status: {paymentStatus}</p>}

      <button onClick={handlePaymentStatus}>Check Payment Status</button>
    </div>
  );
};

export default DepositForm;
