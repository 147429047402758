import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa6";
import { AiOutlineLoading } from "react-icons/ai";
import { doAdminLogin, doAdminLogout } from "../../apis/adminAxios";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    // Check if the admin is already logged in
    const admin = JSON.parse(localStorage.getItem("admin"));
    if (admin) {
      // Redirect to the dashboard if logged in
      navigate("/dashboard");
    }
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const payload = {
      strEmail: email,
      strPassword: password,
    };

    doAdminLogin(payload)
      .then((res) => {
        if (res.success == false) {
          setIsLoading(false);
          setError(res.message);
        } else {
          localStorage.setItem("admin", JSON.stringify(res.data));
          setTimeout(() => {
            setIsLoading(false);
            navigate("/dashboard");
          }, 1000);
        }
      })
      .catch((error) => {});
  };
  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prevState) => !prevState);
  };
  // Updated onChange handlers to reset the error state
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (error) setError(""); // Reset error message
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    if (error) setError(""); // Reset error message
  };

  return (
    <div className="flex items-center justify-center h-screen bg-gradient-to-r from-gray-800 to-gray-600">
      <div className="bg-white shadow-lg rounded-lg p-8 w-[560px]">
        <div className="flex justify-center mb-6">
          {/* Replace with your company logo */}
          <img
            src="/logo.png"
            alt="Company Logo"
            className="w-32 object-contain bg-gray-800"
          />
        </div>
        <div className="contents">
          <h1 className="text-xl font-medium">Welcome To Isap!</h1>
          <h1 className="text-md py-2">
            Please sign in to your account and start your journey with isap
          </h1>
        </div>
        {/* <h2 className="text-2xl font-bold text-center text-gray-800 mb-8">Admin Login</h2> */}
        <form onSubmit={handleSubmit} className="space-y-6 mt-5">
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-200"
              value={email}
              // onChange={(e) => setEmail(e.target.value)}
              onChange={handleEmailChange} // Use the updated handler
              required
            />
          </div>

          <div className="relative">
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700"
            >
              Password
            </label>
            <input
              type={isPasswordVisible ? "text" : "password"}
              id="password"
              className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-200"
              value={password}
              // onChange={(e) => setPassword(e.target.value)}
              onChange={handlePasswordChange} // Use the updated handler
              required
            />
            <div
              className="absolute inset-y-12 right-0 pr-3 flex items-center  cursor-pointer"
              onClick={togglePasswordVisibility}
            >
              {isPasswordVisible ? (
                <FaRegEye className="text-gray-500 text-xl" />
              ) : (
                <FaRegEyeSlash className="text-gray-500 text-xl" />
              )}
            </div>
          </div>
          <div>
            <button
              type="submit"
              className={`w-full p-3 bg-gray-800 text-white font-semibold rounded-md shadow-lg transform transition-all duration-300 ${
                isLoading ? "scale-95" : "hover:scale-105"
              } ${
                isLoading
                  ? "bg-gray-600 cursor-not-allowed"
                  : "hover:bg-gray-600"
              }`}
              disabled={isLoading}
            >
              {isLoading ? "Logging in..." : "Login"}
            </button>
            {error ? (
              <div className="flex justify-center items-center py-2 mt-2 bg-red-200  text-center rounded-md text-black">
                <div>{error}</div>
              </div>
            ) : null}
          </div>
        </form>
        {isLoading && (
          <div className="mt-4 text-center text-gray-800">
            {/* <AiOutlineLoading className="animate-spin text-center"/> */}
            <svg
              className="animate-spin h-5 w-5 mx-auto"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
              ></path>
            </svg>
          </div>
        )}
      </div>
    </div>
  );
}

export default Login;
