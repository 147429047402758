import axios from "axios";

const paymentStatusAxios = axios.create({
  baseURL: "https://wallet-admin.isap.exchange/v2/api",
  headers: {
    "Content-Type": "application/json",
  },
  // You can add headers or other configurations here
});

export const doGetPaymentStatus = () => {
  return new Promise(async (resolve, reject) => {
    paymentStatusAxios
      .post("/card-payment/payment_callback")
      .then((data) => {
        resolve(data.data);
      })

      .catch((err) => {
        console.log("reponse error++", err.status);
        reject(err);
      });
  });
};

// Add more admin-related API calls here
