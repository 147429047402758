// // 


// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import sumsub from '@sumsub/websdk';


// const KYCComponent = () => {
//   const [userId, setUserId] = useState("");
//   const [country, setCountry] = useState("");
//   const [accessToken, setAccessToken] = useState("");
//   const [iframeUrl, setIframeUrl] = useState("");
//   const [error, setError] = useState("");
//   const [token, setToken] = useState("");

//   // Function to start KYC process
//   const startKYC = async () => {
//     try {
//       // Create the recipient (applicant)
//       const createRecipientResponse = await axios.post(
//         "http://localhost:3001/v2/api/kyc/create-recipient", // Make sure this URL is correct
//         { userId, country }
//       );
//       const applicantId = createRecipientResponse.data.data;

//       // Generate access token
//       const tokenResponse = await axios.post(
//         "http://localhost:3001/v2/api/kyc/generate-access-token", // This should be your endpoint for token generation
//         { applicantId,userId }
//       );
//       console.log(tokenResponse.data,"token response")
//       const token = tokenResponse.data.data;
//       setToken(token)

//       // const url = `https://sandbox.sumsub.com/app/websdk/iframe/${applicantId}?token=${token}`;
//       // setIframeUrl(url);
//     } catch (error) {
//       console.error("Error starting KYC:", error);
//       setError("An error occurred while starting KYC. Please try again.");
//     }
//   };
//   // useEffect(() => {
//   //   const widget = sumsub.init(token, {
//   //     lang: 'en', // Choose language
//   //     email: 'user@example.com', // Optionally pass the user's email
//   //     phone: '+1234567890', // Optionally pass the user's phone number
//   //     onMessage: (type, payload) => {
//   //       console.log('Sumsub message:', type, payload);
//   //     },
//   //     onError: (error) => {
//   //       console.error('Sumsub error:', error);
//   //     },
//   //     onSubmit: () => {
//   //       console.log('Verification submitted!');
//   //     }
//   //   }, 'sandbox'); // Use 'sandbox' or 'prod' environment

//   //   widget.on('loaded', () => console.log('Widget loaded'));
//   //   widget.on('error', (error) => console.error('Widget error', error));

//   //   return () => widget.destroy();
//   // }, [token]);

//   useEffect(() => {
//     if (!token) return; // Ensure token is available before initializing the widget
  
//     const widget = sumsub.init(token, {
//       lang: 'en', // Choose language
//       email: 'user@example.com', // Optionally pass the user's email
//       phone: '+1234567890', // Optionally pass the user's phone number
//       onMessage: (type, payload) => {
//         console.log('Sumsub message:', type, payload);
//       },
//       onError: (error) => {
//         console.error('Sumsub error:', error);
//       },
//       onSubmit: () => {
//         console.log('Verification submitted!');
//       },
//       updateAccessToken: async (done) => {
//         try {
//           // Generate a new access token when needed
//           const tokenResponse = await axios.post(
//             "http://localhost:3001/v2/api/kyc/generate-access-token", // Your token generation endpoint
//             { applicantId: userId } // Pass the applicantId or necessary data
//           );
//           const newToken = tokenResponse.data.data;
//           done(null, newToken); // Provide the new token to the SDK
//         } catch (err) {
//           done(err); // Handle any errors during token generation
//         }
//       }
//     }, 'sandbox'); // Use 'sandbox' or 'prod' environment
  
//     widget.on('loaded', () => console.log('Widget loaded'));
//     widget.on('error', (error) => console.error('Widget error', error));
  
//     return () => widget.destroy();
//   }, [token, userId]);

  

//   return (
//     <div className="ml-[260px]">
//       {iframeUrl ? (
//         <div>
//           <iframe
//             src={iframeUrl}
//             title="KYC Verification"
//             width="100%"
//             height="600px"
//             style={{ border: "none" }}
//           ></iframe>
//         </div>
//       ) : (
//         <div>
//           <input
//             type="text"
//             placeholder="User ID"
//             value={userId}
//             onChange={(e) => setUserId(e.target.value)}
//           />
//           <input
//             type="text"
//             placeholder="Country Code"
//             value={country}
//             onChange={(e) => setCountry(e.target.value)}
//           />
//           <button onClick={startKYC}>Start KYC</button>
//           {error && <p>{error}</p>}
//         </div>
//       )}
//     </div>
//   );
// };

// export default KYCComponent;



import React, { useEffect, useState } from "react";
import axios from "axios";
import sumsub from '@sumsub/websdk';

const KYCComponent = () => {
  const [userId, setUserId] = useState("");
  const [applicantId, setApplicant] = useState("");
  const [country, setCountry] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [iframeUrl, setIframeUrl] = useState("");
  const [error, setError] = useState("");
  const [token, setToken] = useState("");

  // Function to start KYC process
  const startKYC = async () => {
    try {
      // Create the recipient (applicant)
      const createRecipientResponse = await axios.post(
        "http://localhost:3001/v2/api/kyc/create-recipient", // Make sure this URL is correct
        { userId, country }
      );
      const applicantId = createRecipientResponse.data.data;
      setApplicant(applicantId)

      // Generate access token
      const tokenResponse = await axios.post(
        "http://localhost:3001/v2/api/kyc/generate-access-token", // This should be your endpoint for token generation
        { applicantId, userId }
      );
      console.log(tokenResponse.data, "token response");
      const token = tokenResponse.data.data;
      setToken(token);
      setAccessToken(token); // Set access token for use in widget

    } catch (error) {
      console.error("Error starting KYC:", error);
      setError("An error occurred while starting KYC. Please try again.");
    }
  };

  useEffect(() => {
    if (!token) return; // Ensure token is available before initializing the widget

    const widget = sumsub.init(accessToken, {
      lang: 'en', // Choose language
      email: 'user@example.com', // Optionally pass the user's email
      phone: '+1234567890', // Optionally pass the user's phone number
      onMessage: (type, payload) => {
        console.log('Sumsub message:', type, payload);
      },
      onError: (error) => {
        console.error('Sumsub error:', error);
      },
      onSubmit: () => {
        console.log('Verification submitted!');
      },
      updateAccessToken: async (done) => {
        try {
          // Generate a new access token when needed
          const tokenResponse = await axios.post(
            "http://localhost:3001/v2/api/kyc/generate-access-token", // Your token generation endpoint
            { applicantId, userId } // Ensure applicantId is correctly set
          );
          const newToken = tokenResponse.data.data;
          done(null, newToken); // Provide the new token to the SDK
        } catch (err) {
          done(err); // Handle any errors during token generation
        }
      }
    }, 'sandbox'); // Use 'sandbox' or 'prod' environment

    widget.on('loaded', () => console.log('Widget loaded'));
    widget.on('error', (error) => console.error('Widget error', error));

    return () => widget.destroy();
  }, [token, accessToken, userId]);

  return (
    <div className="ml-[260px]">
      {iframeUrl ? (
        <div>
          <iframe
            src={iframeUrl}
            title="KYC Verification"
            width="100%"
            height="600px"
            style={{ border: "none" }}
          ></iframe>
        </div>
      ) : (
        <div>
          <input
            type="text"
            placeholder="User ID"
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
          />
          <input
            type="text"
            placeholder="Country Code"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
          />
          <button onClick={startKYC}>Start KYC</button>
          {error && <p>{error}</p>}
        </div>
      )}
    </div>
  );
};

export default KYCComponent;
