import React, { useState } from "react";
import { doAddAdmin } from "../../apis/adminAxios"; // Adjust the import path as necessary
import { useNavigate } from "react-router-dom";
import AdminModals from "../../Components/Modals/AdminModals";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import the CSS file
import { MdDriveFileRenameOutline } from "react-icons/md";
import { IoIosMail } from "react-icons/io";
import { RiLockPasswordFill } from "react-icons/ri";

function CreateAdmin() {
  const navigate = useNavigate();

  const admin = localStorage.getItem("admin");
  const adminDetails = JSON.parse(admin);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [adminType, setAdminType] = useState("ADMIN"); // Default to ADMIN
  const [loading, setLoading] = useState(false); // For loading state
  const [error, setError] = useState(""); // For error messages
  const [success, setSuccess] = useState(""); // For success messages
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    setSuccess("");
    if (!adminDetails) {
      navigate("/login");
    }

    try {
      const adminTypeValue = adminType === "ADMIN" ? 1 : 2;
      const payload = {
        strLoginUserId: adminDetails.fkAdminId,
        strStatus: "N",
        strEmail: email,
        strName: username,
        strPassword: password,
        strUserType: adminTypeValue,
      };
      doAddAdmin(payload, adminDetails.token)
        .then((res) => {
          if (res.success === false) {
            setError(res.message || "Failed to create admin.");
            toast.error(res.message || "Failed to create admin."); // Error toast
          } else {
            toast.success("Admin created successfully!"); // Success toast
            handleClear();
            setIsModalOpen(false);
            setSuccess("Admin created successfully!");
          }
        })
        .catch((error) => {
          if (error.status == 401) {
            localStorage.removeItem("admin");
            navigate("/login");
          } else {
            toast.error("An error occurred. Please try again."); // Error toast
          }
        });
    } catch (err) {
      if (error.status == 401) {
        localStorage.removeItem("admin");
        navigate("/login");
      }
      setError("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setIsModalOpen(true); // Open the confirmation modal
  };

  const handleClear = () => {
    setSuccess("");
    setError("");
    setUsername("");
    setEmail("");
    setPassword("");
    setAdminType("ADMIN");
  };

  return (
    <div className="flex justify-center   h-screen bg-gray-100 mt-10 ml-[260px]">
      <div className="bg-white p-8 mt-10 rounded-lg shadow-lg    h-[450px]  w-3/6">
        <div className="title pt-5">
          <h2 className="text-xl font-medium mb-6 text-gray-600 text-center">
            Create Admin
          </h2>
        </div>
        <form onSubmit={handleFormSubmit} className="">
          <div className="flex flex-col items-center gap-5">
            <div className="flex flex-col  w-5/6">
              <div className="flex flex-col w-6/6 relative">
                <MdDriveFileRenameOutline className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
                <input
                  type="text"
                  id="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="px-10 py-2 text-gray-500 border border-gray-200 rounded-lg focus:outline-none focus:ring-1 focus:ring-gray-200"
                  placeholder="Username"
                  required
                />
              </div>
            </div>

            <div className="flex flex-col w-5/6">
              <div className="flex flex-col w-6/6 relative">
                <IoIosMail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-1 focus:ring-gray-200"
                  placeholder="Email"
                  required
                />
              </div>
            </div>
            <div className="flex flex-col w-5/6">
              <div className="flex flex-col w-6/6 relative">
                <RiLockPasswordFill className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-1 focus:ring-gray-200"
                  placeholder="Password"
                  required
                />
              </div>
            </div>
            <div className="flex flex-col w-5/6">
              {/* <label className="mb-1 text-gray-700" htmlFor="adminType">
              Admin Type <span className="text-red-500">*</span>
            </label> */}
              <select
                id="adminType"
                value={adminType}
                onChange={(e) => setAdminType(e.target.value)}
                className="px-4 py-2  border  border-gray-200 text-gray-400 rounded-lg focus:outline-none focus:ring-1 focus:ring-gray-200"
                required
              >
                <option value="ADMIN">Admin</option>
                <option value="SUB ADMIN">Sub-Admin</option>
              </select>
            </div>
          </div>

          {error && (
            <div className="text-red-500 font-semibold mt-2">{error}</div>
          )}
          {success && (
            <div className="text-green-500 font-semibold mt-2">{success}</div>
          )}

          <div className="flex justify-end gap-5 mx-14 pt-10">
            <button
              type="button"
              onClick={handleClear}
              className="px-4 w-1/6 py-2 bg-gray-400 text-white font-semibold rounded-lg hover:bg-gray-800 transition-colors duration-200"
              disabled={loading} // Disable while loading
            >
              Clear
            </button>
            <button
              type="submit"
              className="px-4 py-2 w-1/6 bg-gray-800 text-white font-semibold rounded-lg hover:bg-gray-600 transition-colors duration-200"
              disabled={loading} // Disable while loading
            >
              {loading ? "Creating..." : "Create"}
            </button>
          </div>
        </form>
        {/* Confirmation Modal */}
        <AdminModals
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          title="Confirm Action"
          onConfirm={handleSubmit} // Confirm action to submit the form
          message="Are you sure you want to create this admin?"
        />
        <ToastContainer />
      </div>
    </div>
  );
}

export default CreateAdmin;
