import axios from "axios";

const adminAxios = axios.create({
  // baseURL: "http://localhost:3000/v2/api",
  baseURL: "https://wallet-admin.isap.exchange/v2/api",

  headers: {
    "Content-Type": "application/json",
  },
  // You can add headers or other configurations here
});

export const doGetAllUsers = (formdata, token) => {
  return new Promise(async (resolve, reject) => {
    adminAxios
      .post("/user/get_all_users", formdata, {
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header
        },
      })
      .then((data) => {
        resolve(data.data);
      })

      .catch((err) => {
        console.log("reponse error++", err.status);
        reject(err);
      });
  });
};

// Add more admin-related API calls here
