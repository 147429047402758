import { Link } from "react-router-dom";
import { useState } from "react";

import {
  FiUsers,
  FiChevronDown,
  FiChevronUp,
  FiUserPlus,
  FiList,
} from "react-icons/fi";
import { FaCogs, FaWallet, FaBusinessTime } from "react-icons/fa";
import { AiOutlineDashboard } from "react-icons/ai";
import { IoIosBusiness } from "react-icons/io";
import { FaUsers } from "react-icons/fa6";
import { RiNotificationBadgeFill } from "react-icons/ri";

function Sidebar() {
  const [isAdminOpen, setIsAdminOpen] = useState(false);
  const [isMerchantOpen, setIsMerchantOpen] = useState(false);
  const [isUserOpen, setIsUserOpen] = useState(false);
  const [isBusinessOpen, setIsBusinessOpen] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  return (
    // <div className="h-screen w-64 bg-gray-900 text-white flex flex-col ">
    <div className="fixed top-0 left-0 h-screen w-64 bg-gray-900 text-white flex flex-col">
      {/* Company Logo */}
      <div className="flex items-center justify-center h-20  border-b border-gray-800 text-white">
        <a href="/dashboard">
          <img
            // src="https://isap.exchange/assets/images/logo/EXCHANGE-LOGO.png"
            src="/logo.png"
            alt="Company Logo"
            className="h-32 w-full object-contain text-white"
          />
        </a>
      </div>

      {/* Sidebar Content */}
      <nav className="flex-grow mt-10">
        <ul>
          {/* dashbaord */}
          <li className="mb-2">
            <Link
              to="/dashboard"
              className="flex  items-center text-gray-400 hover:bg-gray-800 hover:text-white rounded transition-colors duration-200"
            >
              <div
                // onClick={() => setIsAdminOpen(!isAdminOpen)}
                className="flex items-center justify-between py-3 px-6 text-gray-400 hover:bg-gray-800 hover:text-white rounded transition-colors duration-200 cursor-pointer"
              >
                <div className="flex items-center">
                  <AiOutlineDashboard className="mr-3 text-xl" />
                  <span>Dashbaord</span>
                </div>
              </div>
            </Link>
          </li>

          {/* Admin Dropdown */}
          <li className="mb-2">
            <div
              onClick={() => setIsAdminOpen(!isAdminOpen)}
              className="flex items-center justify-between py-3 px-6 text-gray-400 hover:bg-gray-800 hover:text-white rounded transition-colors duration-200 cursor-pointer"
            >
              <div className="flex items-center">
                <FiUsers className="mr-3" />
                <span>Admin</span>
              </div>
              {isAdminOpen ? <FiChevronUp /> : <FiChevronDown />}
            </div>
            {isAdminOpen && (
              <ul className="pl-12 mt-2">
                <li className="mb-2">
                  <Link
                    to="/admin/view"
                    className="flex  items-center py-2 px-4 text-gray-400 hover:bg-gray-800 hover:text-white rounded transition-colors duration-200"
                  >
                    <FiList className="mr-2" />
                    View Admin
                  </Link>
                </li>
                <li className="mb-2">
                  <Link
                    to="/admin/add"
                    className="flex  items-center py-2 px-4 text-gray-400 hover:bg-gray-800 hover:text-white rounded transition-colors duration-200"
                  >
                    <FiUserPlus className="mr-2" />
                    Add Admin
                  </Link>
                </li>
              </ul>
            )}
          </li>
          {/* user view */}
          <li className="mb-2">
            <div
              onClick={() => setIsUserOpen(!isUserOpen)}
              className="flex items-center justify-between py-3 px-6 text-gray-400 hover:bg-gray-800 hover:text-white rounded transition-colors duration-200 cursor-pointer"
            >
              <div className="flex items-center">
                <FaUsers className="mr-3" />
                <span>Users</span>
              </div>
              {isUserOpen ? <FiChevronUp /> : <FiChevronDown />}
            </div>
            {isUserOpen && (
              <ul className="pl-12 mt-2">
                <li className="mb-2">
                  <Link
                    to="/users/View"
                    className="flex items-center py-2 px-4 text-gray-400 hover:bg-gray-800 hover:text-white rounded transition-colors duration-200"
                  >
                    <FiList className="mr-2" />
                    List Users
                  </Link>
                </li>
                <li className="mb-2">
                  <Link
                    to="/user/trasaction"
                    className="flex items-center py-2 px-4 text-gray-400 hover:bg-gray-800 hover:text-white rounded transition-colors duration-200"
                  >
                    <FaWallet className="mr-2" />
                    User Wallet
                  </Link>
                </li>
              </ul>
            )}
          </li>

          {/* merchant Dropdown */}
          <li className="mb-2">
            <div
              onClick={() => setIsMerchantOpen(!isMerchantOpen)}
              className="flex items-center justify-between py-3 px-6 text-gray-400 hover:bg-gray-800 hover:text-white rounded transition-colors duration-200 cursor-pointer"
            >
              <div className="flex items-center">
                <FaBusinessTime className="mr-3" />
                <span>Merchants</span>
              </div>
              {isMerchantOpen ? <FiChevronUp /> : <FiChevronDown />}
            </div>
            {isMerchantOpen && (
              <ul className="pl-12 mt-2">
                <li className="mb-2">
                  <Link
                    to="/settings/profile"
                    className="flex items-center py-2 px-4 text-gray-400 hover:bg-gray-800 hover:text-white rounded transition-colors duration-200"
                  >
                    <FiList className="mr-2" />
                    view merchants
                  </Link>
                </li>
                <li className="mb-2">
                  <Link
                    to="/settings/security"
                    className="flex items-center py-2 px-4 text-gray-400 hover:bg-gray-800 hover:text-white rounded transition-colors duration-200"
                  >
                    <FiList className="mr-2" />
                    Add merchants
                  </Link>
                </li>
              </ul>
            )}
          </li>

          {/* business category open */}
          <li className="mb-2">
            <div
              onClick={() => setIsBusinessOpen(!isBusinessOpen)}
              className="flex items-center justify-between py-3 px-6 text-gray-400 hover:bg-gray-800 hover:text-white rounded transition-colors duration-200 cursor-pointer"
            >
              <div className="flex items-center">
                <IoIosBusiness className="mr-3" />
                <span>Business Category</span>
              </div>
              {isBusinessOpen ? <FiChevronUp /> : <FiChevronDown />}
            </div>
            {isBusinessOpen && (
              <ul className="pl-12 mt-2">
                <li className="mb-2">
                  <Link
                    to="/settings/profile"
                    className="flex items-center py-2 px-4 text-gray-400 hover:bg-gray-800 hover:text-white rounded transition-colors duration-200"
                  >
                    <FiList className="mr-2" />
                    view Categories
                  </Link>
                </li>
                <li className="mb-2">
                  <Link
                    to="/settings/security"
                    className="flex items-center py-2 px-4 text-gray-400 hover:bg-gray-800 hover:text-white rounded transition-colors duration-200"
                  >
                    <FiList className="mr-2" />
                    Add Categories
                  </Link>
                </li>
              </ul>
            )}
          </li>


        {/* nottfication  */}
           {/* merchant Dropdown */}
           <li className="mb-2">
            <div
              onClick={() => setIsNotificationOpen(!isNotificationOpen)}
              className="flex items-center justify-between py-3 px-6 text-gray-400 hover:bg-gray-800 hover:text-white rounded transition-colors duration-200 cursor-pointer"
            >
              <div className="flex items-center">
                <RiNotificationBadgeFill className="mr-3" />
                <span>Nottification</span>
              </div>
              {isNotificationOpen ? <FiChevronUp /> : <FiChevronDown />}
            </div>
            {isNotificationOpen && (
              <ul className="pl-12 mt-2">
                <li className="mb-2">
                  <Link
                    to="/settings/profile"
                    className="flex items-center py-2 px-4 text-gray-400 hover:bg-gray-800 hover:text-white rounded transition-colors duration-200"
                  >
                    <FiList className="mr-2" />
                    view Notification
                  </Link>
                </li>
                <li className="mb-2">
                  <Link
                    to="/settings/security"
                    className="flex items-center py-2 px-4 text-gray-400 hover:bg-gray-800 hover:text-white rounded transition-colors duration-200"
                  >
                    <FiList className="mr-2" />
                    Add Notification
                  </Link>
                </li>
              </ul>
            )}
          </li>
        </ul>
      </nav>

      {/* Footer */}
      <div className="mt-auto text-center p-6 border-t border-gray-800">
        <span className="text-sm text-gray-500">© 2024 Your Company</span>
      </div>
    </div>
  );
}

export default Sidebar;
