import axios from "axios";

const adminAxios = axios.create({
  baseURL: "https://wallet-admin.isap.exchange/v2/api",
  headers: {
    "Content-Type": "application/json",
  },
  // You can add headers or other configurations here
});
export const doAdminLogin = (formdata) => {
  return new Promise(async (resolve, reject) => {
    adminAxios
      .post("/admin/admin_login", formdata)
      .then((res) => {
        resolve(res.data);
      })

      .catch((err) => {
        console.log("reponse error");
        reject(err);
      });
  });
};
export const doAdminLogout = (formdata) => {
  return new Promise(async (resolve, reject) => {
    adminAxios
      .post("/admin/admin_logout", formdata)
      .then((res) => {
        console.log(res, "loggout message");
        resolve(res.data);
      })

      .catch((err) => {
        console.log("reponse error");
        reject(err);
      });
  });
};
export const doGetAllAdmins = (formdata, token) => {
  return new Promise(async (resolve, reject) => {
    adminAxios
      .post("/admin/get_all_admins", formdata, {
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header
        },
      })
      .then((data) => {
        resolve(data.data);
      })

      .catch((err) => {
        console.log("reponse error++", err.status);
        reject(err);
      });
  });
};
export const doAddAdmin = (formdata, token) => {
  return new Promise(async (resolve, reject) => {
    adminAxios
      .post("/admin/add_admins", formdata, {
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header
        },
      })
      .then((data) => {
        resolve(data.data);
      })

      .catch((err) => {
        console.log("reponse error");
        reject(err);
      });
  });
};
export const doUpdateAdmin = (formdata, token) => {
  return new Promise(async (resolve, reject) => {
    adminAxios
      .post("/admin/update_admins", formdata, {
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header
        },
      })
      .then((data) => {
        resolve(data.data);
      })

      .catch((err) => {
        console.log("reponse error");
        reject(err);
      });
  });
};
export const doUpdateAdminPasswd = (formdata) => {
  return new Promise(async (resolve, reject) => {
    adminAxios
      .post("/admin/update_admin_passwd", formdata)
      .then((data) => {
        resolve(data.data);
      })

      .catch((err) => {
        console.log("reponse error");
        reject(err);
      });
  });
};

// Add more admin-related API calls here
