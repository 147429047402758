import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { doGetAllUsers } from "../../apis/userAxios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; //
import { FiArrowLeft } from "react-icons/fi";

function DetailView() {
  const { id } = useParams(); // Get the admin ID from the URL
  const navigate = useNavigate();
  const admin = localStorage.getItem("admin");
  const adminDetails = JSON.parse(admin);
  if (!adminDetails) {
    navigate("/login");
  }
  const [user, setUsers] = useState(null);

  useEffect(() => {
    const payload = {
      strLoginUserId: adminDetails.fkAdminId,
      fkUserId: id,
      intSkipCount: 0, // Calculate skip based on page number
      intPageLimit: 1,
    };
    doGetAllUsers(payload, adminDetails.token)
      .then((res) => {
        if (res.success === false) {
          toast.error(res.message);
        } else {
          setUsers(res.data);
        }
      })
      .catch((error) => {
        if (error.status == 401) {
          localStorage.removeItem("admin");
          navigate("/login");
        }
      });
  }, [id]);
  if (!user) return <div>Loading...</div>;

  return (
    <div className="ml-[260px]  h-screen">
      <div className="bg-white shadow-lg mx-10 mt-10 rounded-lg p-20">
        <button
          onClick={() => navigate(-1)}
          className="text-gray-600 mb-4 flex items-center gap-2 hover:text-gray-900"
        >
          <FiArrowLeft />
          Back to Users
        </button>
        <h2 className="text-2xl font-semibold mb-4">User Details</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="border p-5 flex flex-col justify-center ">
            <h3 className="text-xl font-medium">Personal Information</h3>
            <p className="mt-2 text-gray-500">
              <span className="font-semibold">Name:</span>{" "}
              {user[0].strFirstName} {user.strLastName}
            </p>
            <p className="text-gray-500">
              <span className="font-semibold">Email:</span> {user[0].strEmail}
            </p>
            <p className="text-gray-500">
              <span className="font-semibold">Phone:</span> {user[0].strPhoneNo}
            </p>
            <p className="text-gray-500">
              <span className="font-semibold">DOB: </span>
              {new Date(user[0].strDateOfBirth).toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
            </p>
          </div>
          <div className="border p-5">
            <h3 className="text-xl font-medium">Account Information</h3>
            <p className="mt-2 text-gray-500">
              <span className="font-semibold">Account Status:</span> Active
            </p>
            <p className="text-gray-500">
              <span className="font-semibold">Wallet Balance:</span>{" "}
              {user[0].walletBalence}
            </p>
            <p className="text-gray-500">
              <span className="font-semibold">Currency:</span>
              {user[0].currency}
            </p>
            <p className="text-gray-500">
              <span className="font-semibold">KYC Status:</span>{" "}
              {user[0].statusKYC}
            </p>
          </div>
          <div className="border p-5">
            <h3 className="text-xl font-medium">Address Details</h3>
            <p className="mt-2 text-gray-700">
              <span className="font-semibold">Country:</span>{" "}
              {user[0].strCountry}
            </p>
            <p className="text-gray-500">
              <span className="font-semibold">State:</span> {user[0].strState}
            </p>
            <p className="text-gray-500">
              <span className="font-semibold">City:</span> {user[0].strCity}
            </p>
            <p className="text-gray-500">
              <span className="font-semibold">ZIpCode:</span>{" "}
              {user[0].strZipCode}
            </p>
            <p className="text-gray-500">
              <span className="font-semibold">Adress:</span>{" "}
              {user[0].strAddress1}
            </p>
          </div>
          <div className="border p-5">
            <h3 className="text-xl font-medium">Account Information</h3>
            <p className="mt-2 text-gray-500">
              <span className="font-semibold">Account Status:</span>  Active
            </p>
            <p className="text-gray-500">
              <span className="font-semibold">Wallet Balance:</span>{" "}
              {user[0].currency + " " + user[0].walletBalence}
            </p>
            <p className="text-gray-500">
              <span className="font-semibold">KYC Status:</span>{" "}
              {user[0].statusKYC}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailView;
