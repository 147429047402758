import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { doGetPaymentStatus } from "../../apis/paymentConfirm";

function PaymentConfirm() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const status = queryParams.get("status");

  let message;
  if (status === "success") {
    message = "Payment completed successfully!";
  } else if (status === "failed") {
    message = "Payment failed. Please try again.";
  } else {
    message = "An error occurred. Please contact support.";
  }

  return (
    <div className="flex justify-center bg-white">
      <div className="border p-20 mt-20">
        <h1 className="text-center font-medium text-3xl">{message}</h1>
      </div>
    </div>
  );
}

export default PaymentConfirm;
